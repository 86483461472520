<template>
  <div class="app_con">
    <div class="p-20">
      <div class="m-b-20">
        <el-button type="primary" @click="showDig(0)" size="small">新增</el-button>
      </div>
      <div class="table-wrap">
        <el-table :data="dataList" border style="width: 100%" row-key="id" @selection-change="getSelection($event)">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="storeName" label="店铺" align="center"></el-table-column>
          <el-table-column prop="storeName" label="时段" align="center">

            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.time" :key="index">
                {{ item.startTime }}-{{ item.endTime }}
              </span>
            </template>
          </el-table-column>



        </el-table>
      </div>

      <el-pagination background :current-page.sync="pageInfo.pageNum" :page-size="pageInfo.pageSize"
        :total="pageInfo.total" :layout="'sizes, prev, pager, next, jumper'" @size-change="onSizeChange"
        @current-change="onPageChange" />
    </div>

    <el-dialog title="设置自提时段" :visible.sync="digShow" width="800px" @close="digShow = false">

      <div class="selCon m-b-10">

        <label class="commonLabel">选择店铺</label>
        <el-select v-model="form.storeId" placeholder="请选择店铺（可搜索）" filterable size="small">
          <el-option v-for="(item, index) in storeList" :key="index" :value="item.id" :label="item.storeName"></el-option>
        </el-select>
      </div>

      <div class="timeItem m-b-10" v-for="(item, index) in form.timeItem" :key="index">
        <span class="commonLabel">自提时段{{ index + 1 }}</span>
        <el-time-picker is-range v-model="form.timeItem[index]" range-separator="至" start-placeholder="开始时间"
          end-placeholder="结束时间" placeholder="选择时间范围" @change="showRes" size="small" value-format="HH:mm:ss"
          format="HH:mm:ss">
        </el-time-picker>
        <el-button type="" @click="removeItem(index)" size="small">删除</el-button>

      </div>
      <span slot="footer">
        <el-button type="primary" @click="addArrItem">新增一组</el-button>
        <el-button type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";
export default {
  data() {
    // let self = this;
    return {
      dataList: [],
      storeList: [],
      handleType: '',
      digShow: false,
      form: { storeId: "", timeItem: [""] },
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 15,
        total: 0,
        apiName: "/storeTakeTime",
      },
      
    };
  },
  created() {
    this.getDataList();
  },
  watch: {
    // filtterData: {
    //   handler() {
    //     this.getList();
    //   },
    //   // immediate: true,
    //   deep: true, //开启深度监听
    // },
  },
  mounted() {

    this.getStoreList()
  },

  filters: {},

  methods: {

    async getStoreList() {

      let res = await api.commonGet({ apiName: '/store', pageSize: 100 });
      if (res.code == 200) {
        this.storeList = res.data.data
      }
    },


    showDig(type, row = null) {

      if (type == 0) {
        this.handleType = "create"
        this.form = { storeId: "", timeItem: [""] }
      }

      else {

        this.handleType = "update"
        this.form = row
      }
      this.digShow = true
    },
    async handleSave() {


      if (this.form.timeItem.length == 0) {
        this.$message.error("请至少添加一个时间段")
        return
      }

      let isEmpty = this.form.timeItem.findIndex(item => {

        return item == ""
      })
      if (isEmpty !== -1) {
        this.$message.error("请勿添加空时段")
        return
      }
      let itemList = [];
      this.form.timeItem.forEach(element => {
        itemList.push({ startTime: element[0], endTime: element[1] })
      });


      let res = await api.commonPost({ apiName: '/storeTakeTime/' + this.handleType, storeId: this.form.storeId, itemList: itemList });
      if (res.code == 200) {
        this.$message.success('设置成功')
        this.digShow = false
        this.getDataList()
      }


    },

    removeItem(index) {
      this.form.timeItem.splice(index, 1)
    },

    showRes(e) {

      console.log(e);
    },
    addArrItem() {

      this.form.timeItem.push("")
    },
    async getDataList() {
      try {
        let res = await api.common.commonGet(this.pageInfo);
        this.dataList = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
  },
};
</script>

<style scoped>
.timeItem span {
  display: inline-block;
  border: 1px solid #dcdfe6;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 10px;
  line-height: 30px;
  background: #f5f7fa;
  border-right: none;
  color: #909399;
  font-size: 14px;
}
</style>
